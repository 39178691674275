/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'PaintersFilter/updateFilter',
        reset: 'PaintersFilter/resetFilter',
        resetState: 'PaintersFilter/resetFilterState',
        removeSelected: 'PaintersFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();
        filter.createSearchEntity('name');
        filter.setPlaceholder('name', 'По имени');
        filter.setName('name', 'search_name');
        filter.setPreviewName('name', 'Имя');
        filter.setIconClassName('name', 'icon-user-search');
        filter.setAction('name', 'sellersPainter/updateSearch');

        filter.createSuggestionsEntity('country');
        filter.setPlaceholder('country', 'Начните вводить название страны');
        filter.setName('country', 'suggestions_country');
        filter.setPreviewName('country', 'Страна');
        filter.setAction('country', 'sellersPainter/updateSuggestionsSelectedCountry');
        filter.setSearchAction('country', 'sellersPainter/updateCountrySuggestionsValue');
        filter.setIconClassName('country', 'icon-compass');

        filter.createSuggestionsEntity('city');
        filter.setPlaceholder('city', 'По городу');
        filter.setName('city', 'suggestions_city');
        filter.setPreviewName('city', 'Город');
        filter.setIconClassName('city', 'icon-compass');
        filter.setAction('city', 'sellersPainter/updateSuggestionsSelected');
        filter.setSearchAction('city', 'sellersPainter/updateCitySuggestionsValue');

        this.filterModel = filter.filterModel;
    }
}
