
import { Mixins, Component, Prop } from 'vue-property-decorator';
import ImageBlock from '@/components/imageInput/CollectionItem.vue';
import AddImagesBlock from '@/components/imageInput/NewImagesInput.vue';
import DropOverlay from './DropOverlay.vue';
import Cropper from '@/components/modules/Cropper.vue';

import DragMixin from './DragMixin';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import { ImageCollectionInterface } from '@/lib/formFactory/imageCollection.interface';
import { StringRecord, uploadImage } from '@/admin/api/file';
import { imageUrlToFile } from '@/lib/utils/Utils';

@Component({
    components: {
        ImageBlock,
        AddImagesBlock,
        DropOverlay,
        Cropper,
    },
})
export default class ImageInputCollectionComponent extends Mixins(DragMixin) {
    @Prop({ required: false }) readonly title!: string;
    @Prop({ required: true }) readonly data!: ImageCollectionInterface;
    @Prop({ required: false }) readonly isAllowMainPhoto!: boolean;
    @Prop({ type: Boolean, default: false }) fullSize!: boolean;
    @Prop({ default: 'Auction' }) entity!: string;

    imageCollectionOverlay: HTMLElement | null = null;
    dragEnteredImageIndex: number | null = null;
    imageDraggedIndex: number | null = null;
    isImageDragged = false;
    isCropperShow = false;
    croppPhoto = { value: '', key: '', preview: '' };

    errorMessages: { [key: string]: string } = {
        fileType: 'Ошибка! Неверный формат файла(ов)',
        fileDimensions: 'Изображение не должно быть меньше 480x480 пикселей',
    };

    filesToValidateLength = 0;
    validationIndex = 0;
    validatedFiles: File[] = [];

    handleImage(id: string, file: File): void {
        this.$emit('load-image', { id, file });
    }

    addImages(files: File[]): void {
        this.$emit('add-images', files);

        setTimeout(() => {
            const file = this.data.list[this.data.list.length - 1];

            this.croppPhoto = file;
            this.isCropperShow = true;
        }, 1000);
    }

    async addImagesHandler(fileList: File[]): Promise<void> {
        if (!fileList) {
            return;
        }

        this.addImages(fileList);
    }

    updateMainPhoto(id: string): void {
        this.$emit('update-main-photo', id);
    }

    removeImage(id: string): void {
        this.$emit('remove-image', id);
    }

    async dropHandler(event: DragEvent): Promise<void> {
        event.preventDefault();
        event.stopPropagation();

        if (!event?.dataTransfer?.files?.length) {
            return;
        }

        this.filesToValidateLength = event.dataTransfer.files.length;
        this.validateFiles(event.dataTransfer.files);
    }

    async validateFiles(fileList: FileList): Promise<void> {
        if (!fileList || !Object.values(fileList).length) {
            return;
        }

        for (const file of Object.values(fileList)) {
            if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
                ResponseHandlerModule.showNotify({ message: this.errorMessages['fileType'], type: 'fail' });
                this.incrementValidationIndex();
                continue;
            }

            const fr = new FileReader();

            fr.onload = () => {
                const img = new Image();

                img.onload = () => {
                    if (img.width < 480 || img.height < 480) {
                        ResponseHandlerModule.showNotify({
                            message: this.errorMessages['fileDimensions'],
                            type: 'fail',
                        });
                        this.incrementValidationIndex();

                        return;
                    }

                    this.validatedFiles.push(file);
                    this.incrementValidationIndex();
                };

                img.src = fr.result as string;
            };

            fr.readAsDataURL(file);
        }
    }

    incrementValidationIndex(): void {
        this.validationIndex++;

        if (this.validationIndex === this.filesToValidateLength) {
            this.addImages(this.validatedFiles);
            this.hideOverlay();

            this.filesToValidateLength = 0;
            this.validationIndex = 0;
            this.validatedFiles = [];
        }
    }

    onDropImage(event: DragEvent): void {
        console.info('onDropImage', event);
    }

    onDragEnterImage(index: number): void {
        this.dragEnteredImageIndex = index;
    }

    onDragStartImage(index: number): void {
        this.dragEnteredImageIndex = null;
        this.imageDraggedIndex = index;
        this.isImageDragged = true;
    }

    onDragEndImage(): void {
        if (this.dragEnteredImageIndex === null) {
            this.imageDraggedIndex = null;
            this.isImageDragged = false;

            return;
        }

        if (this.imageDraggedIndex !== this.dragEnteredImageIndex) {
            this.$emit('update-image-sort', { moveFrom: this.imageDraggedIndex, moveTo: this.dragEnteredImageIndex });
        }

        this.imageDraggedIndex = null;
        this.isImageDragged = false;
    }

    async handleCrop(dimensions: StringRecord, id: string): Promise<void> {
        if (this.entity) {
            const preview: any = this.croppPhoto.preview ? await imageUrlToFile(this.croppPhoto.preview) : '';
            const image: any = await uploadImage(preview || this.croppPhoto.value, dimensions, this.entity);

            this.$emit('load-image', { id, file: image?.files?.[0], cropp: true });

            this.isCropperShow = false;
        }
    }
}
