import { Module, Action, getModule } from 'vuex-module-decorators';
import TransactionsBaseModule from '@/admin/store/transactions';
import PageEntity from '@/lib/layouts/page/pageEntity';

import store from '@/admin/store';
import { getTransactionsList } from '@/admin/api/transaction';
import { generateFilter } from '@/lib/utils/Utils';

const MODULE_NAME = 'painterTransactions';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PainterTransactionsModule extends TransactionsBaseModule {
    constructor(module: PainterTransactionsModule) {
        super(module);

        this.isLoading = false;

        const page = new PageEntity();
        page.setSortName('PainterTransactionsSort');
        page.setDefaultSortField('createdAt');
        this.pageSettings = page.values;
    }

    @Action({ rawError: true })
    async getTable(params: { sellerName?: string; catalogName?: string; orderId?: string | number }) {
        this.SET_IS_LOADING(true);

        const promises = [];
        if (!this.operationNames.length) {
            promises.push(this.initOperationNames());
        }

        if (!this.statuses.length) {
            promises.push(this.initOrderStatuses());
        }

        await Promise.all(promises);

        this.SET_REQUEST_PARAMS(params);

        const sort = await this.getSortForRequest();
        const filterItems = [{ id: 'subjectType', value: 'painter' }];

        if (params.sellerName) {
            filterItems.push({ id: 'seller', value: params.sellerName });
        }

        if (params.orderId) {
            filterItems.push({ id: 'order', value: String(params.orderId) });
        }

        const result = await getTransactionsList(
            this.pageSettings.pageCurrent,
            sort,
            generateFilter(filterItems),
            +this.pageSettings.pageAmountItems,
        );

        await this.setInfo(result);
        this.SET_IS_LOADING(false);
    }
}
export default getModule(PainterTransactionsModule);
