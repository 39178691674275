/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'PaintersLotsFilter/updateFilter',
        reset: 'PaintersLotsFilter/resetFilter',
        resetState: 'PaintersLotsFilter/resetFilterState',
        removeSelected: 'PaintersLotsFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();
        filter.createSearchEntity('name');
        filter.setPlaceholder('name', 'По названию');
        filter.setName('name', 'search_name');
        filter.setPreviewName('name', 'Название');
        filter.setIconClassName('name', 'icon-book');
        filter.setClassName('name', 'row-1');
        filter.setAction('name', 'PainterLots/updateSearch');

        filter.createDateIntervalPickerEntity('datetimePosting');
        filter.setName('datetimePosting', 'picker_date');
        filter.setTitle('datetimePosting', 'Время разм.');
        filter.setPreviewName('datetimePosting', 'Вр. разм.');
        filter.setDateIntervalList('datetimePosting', {
            startDate: {
                key: 'datetimePostingStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'PainterLots/updateStartDate',
            },
            endDate: {
                key: 'datetimePostingEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'PainterLots/updateEndDate',
            },
        });
        filter.setClassName('datetimePosting', 'row-2');

        filter.createDateIntervalPickerEntity('dateTimeStartTrad');
        filter.setName('dateTimeStartTrad', 'picker_date');
        filter.setTitle('dateTimeStartTrad', 'Время старта');
        filter.setPreviewName('dateTimeStartTrad', 'Вр. старта');
        filter.setDateIntervalList('dateTimeStartTrad', {
            startDate: {
                key: 'dateTimeStartTradStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'PainterLots/updateStartDate',
            },
            endDate: {
                key: 'dateTimeStartTradEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'PainterLots/updateEndDate',
            },
        });
        filter.setClassName('dateTimeStartTrad', 'row-3');

        this.filterModel = filter.filterModel;
    }
}
