
import { Vue, Component } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/Table.vue';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import WorksModule from '@/admin/store/sellers/painters/works';

@Component({
    components: {
        FlexLayout,
        Loading,
        TableBlock,
        AppHeaderMain,
    },
})
export default class PainterLotsComponent extends Vue {
    get settings() {
        return WorksModule.pageSettings;
    }

    get filterSettings() {
        return WorksModule.filter.filterSettings;
    }

    get isLoading() {
        return WorksModule.isLoading;
    }

    tableAction(params: any) {
        if (params.action === 'sort') {
            WorksModule.sort({ field: params.field, sort: params.sort });
        }
    }

    updateList() {
        WorksModule.getList();
    }

    async resetFilters() {
        await WorksModule.clearSort();
        await WorksModule.filter.resetFilter();
        WorksModule.getList();
    }

    toAdd() {
        this.$router.push({ name: 'painter_work_create' });
    }

    created() {
        WorksModule.init(this.$route.params.painterId);
    }

    beforeDestroy() {
        WorksModule.reset();
    }
}
