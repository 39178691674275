
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ImageInputDropOverlay extends Vue {
    @Prop({ default: true }) isOverlayHidden!: boolean;

    dropText = 'Переместите файлы сюда';

    handleDrop(e: DragEvent): void {
        this.$emit('drop', e);
    }

    emitHide(): void {
        this.$emit('hide');
    }
}
