
import { Vue, Component } from 'vue-property-decorator';
import FlexLayout from '@/admin/layouts/FlexLayout.vue';
import PainterOrders from '@/admin/store/sellers/painters/orders';
import AppHeaderMain from '@/admin/layouts/partials/AppHeaderMain.vue';

import Loading from '@/components/Loading.vue';
import TableBlock from '@/components/table/TableLots.vue';

@Component({
    components: {
        FlexLayout,
        AppHeaderMain,
        Loading,
        TableBlock,
    },
})
export default class PainterOrdersComponent extends Vue {
    get isLoading() {
        return PainterOrders.isLoading;
    }

    get settings() {
        return PainterOrders.pageSettings;
    }

    get filterSettings() {
        return PainterOrders.filter.filterSettings;
    }

    get painterId() {
        return this.$route.params.painterId;
    }

    updateList() {
        PainterOrders.getTable(this.painterId);
    }

    async resetFilters() {
        await PainterOrders.clearSort();
        PainterOrders.getTable(this.painterId);
    }

    created() {
        PainterOrders.init(this.painterId);
    }

    beforeDestroy(): void {
        PainterOrders.reset();
    }
}
