
import { Component, Vue } from 'vue-property-decorator';
import Layout from '@/admin/layouts/Default.vue';
import Profile from './profile/Main.vue';
import Lots from './_lots.vue';
import Works from './_works.vue';
import WorkCard from './WorkCard.vue';
import Users from './_users.vue';
import Orders from './_orders.vue';
import Balance from './_balance.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';

import PainterModule from '@/admin/store/sellers/painters';
import PainterEntity from '@/admin/store/sellers/painters/entity';
import { API_URL } from '@/constants';

@Component({
    components: {
        Layout,
        Profile,
        Lots,
        Works,
        WorkCard,
        Users,
        Orders,
        Balance,
        TitleReturn,
        TabsNav,
    },
})
export default class SellersPainterMain extends Vue {
    returnTitleData = {
        icon: 'bank',
        title: this.painterName,
    };

    get painterName() {
        return PainterEntity.painterName;
    }

    get tabsNav() {
        return PainterModule.tabsNav;
    }

    get currentTab() {
        if (this.$route.name === 'painter_work' || this.$route.name === 'painter_work_create') {
            return 'painter_works';
        }

        if (this.$route.name != null) {
            return this.$route.name;
        }

        return '';
    }

    get publicLink() {
        if (this.$route.name === 'painter_create') {
            return '';
        }

        return `${API_URL}/painters/${this.$route.params.painterId}`;
    }

    async initTitle() {
        if (this.$route.params.painterId) {
            await PainterEntity.initCardNameById(Number(this.$route.params.painterId));
            this.returnTitleData = PainterEntity.titleReturnData.edit;

            return;
        }

        this.returnTitleData = PainterEntity.titleReturnData.create;
    }

    returnBefore() {
        this.$router.push({ name: 'painters' });
    }

    async created() {
        this.initTitle();
    }

    beforeDestroy() {
        PainterEntity.reset();
    }
}
