import { Module } from 'vuex-module-decorators';
import store from '@/admin/store';
import FilterModule from '@/admin/store/filter';

@Module({ dynamic: true, store, name: 'PaintersFilter', namespaced: true })
export default class PaintersFilter extends FilterModule {
    constructor(module: PaintersFilter) {
        super(module);
    }
}
