/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'PainterOrdersFilter/updateFilter',
        reset: 'PainterOrdersFilter/resetFilter',
        resetState: 'PainterOrdersFilter/resetFilterState',
        removeSelected: 'PainterOrdersFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSearchEntity('id');
        filter.setPlaceholder('id', 'По номеру заказа');
        filter.setName('id', 'search_id');
        filter.setPreviewName('id', 'Номер');
        filter.setIconClassName('id', 'icon-book');
        filter.setClassName('id', 'col-1-row-1');
        filter.setAction('id', 'PainterOrders/updateSearch');

        filter.createSearchEntity('costOrderStart');
        filter.setPlaceholder('costOrderStart', 'Сумма от');
        filter.setName('costOrderStart', 'search_cost_order_start');
        filter.setPreviewName('costOrderStart', 'Сумма от');
        filter.setIconClassName('costOrderStart', 'icon-dollar');
        filter.setClassName('costOrderStart', 'col-1-row-2');
        filter.setAction('costOrderStart', 'PainterOrders/updateSearch');

        filter.createSearchEntity('costOrderEnd');
        filter.setPlaceholder('costOrderEnd', 'Сумма до');
        filter.setName('costOrderEnd', 'search_cost_order_start');
        filter.setPreviewName('costOrderEnd', 'Сумма до');
        filter.setIconClassName('costOrderEnd', 'icon-dollar');
        filter.setClassName('costOrderEnd', 'col-1-row-3');
        filter.setAction('costOrderEnd', 'PainterOrders/updateSearch');

        filter.createMultiSelectEntity('status');
        filter.setTitle('status', 'По статусу');
        filter.setName('status', 'multi_select_type');
        filter.setPreviewName('status', 'Статус');
        filter.setInitAction('status', 'PainterOrders/initStatuses');
        filter.setIconClassName('status', 'icon-status');
        filter.setClassName('status', 'col-2-row-1');
        filter.setAction('status', 'PainterOrders/updateMultiSelect');

        filter.createDateIntervalPickerEntity('datetimeCreate');
        filter.setName('datetimeCreate', 'picker_date');
        filter.setTitle('datetimeCreate', 'Время оформ.');
        filter.setPreviewName('datetimeCreate', 'Вр. оформ.');
        filter.setDateIntervalList('datetimeCreate', {
            startDate: {
                key: 'datetimeCreatedStart',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'PainterOrders/updateStartDate',
            },
            endDate: {
                key: 'datetimeCreatedEnd',
                type: 'date-picker',
                title: '',
                placeholder: '',
                value: '',
                action: 'PainterOrders/updateEndDate',
            },
        });
        filter.setClassName('datetimeCreate', 'col-2-row-2');

        this.filterModel = filter.filterModel;
    }
}
