import { Module, getModule, Action } from 'vuex-module-decorators';
import TransactionEntityBaseModule from '@/admin/store/transactionsSellerEntity';

import store from '@/admin/store';
import EntityModel from './transactionModel';
import { generateSort } from '@/lib/utils/Utils';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';
import { SelectItem } from '@/lib/formFactory/select.interface';

const MODULE_NAME = 'PainterTransaction';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PainterTransactionEntity extends TransactionEntityBaseModule {
    model: any;
    selectDefault = { id: 0, value: '' };
    requestSort = '';

    constructor(module: PainterTransactionEntity) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
        this.model.sellerType.current = this.model.sellerType.list.filter(
            (type: SelectItem) => type.id === 'sellerPainter',
        )[0];
        this.requestSort = generateSort({ id: 'name', value: 'ASC' });
    }

    @Action({ rawError: true })
    initSellerInfo(seller: InputSuggestionItem) {
        if (!seller.id) {
            return;
        }

        this.updateSuggestionsSelectedByType({ type: 'sellerPainter', selected: seller });
    }
}
export default getModule(PainterTransactionEntity);
