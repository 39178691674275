import InputEntity from '@/lib/formFactory/inputEntity';
import ImageCollectionEntity from '@/lib/formFactory/imageCollectionEntity';
import InputSuggestionsEntity from '@/lib/formFactory/inputSuggestionsEntity';

export default class EntityModel {
    model: any;

    constructor() {
        const nameEntity = new InputEntity('name');
        nameEntity.setTitle('Название лота');
        nameEntity.setRequired(true);

        const authorEntity = new InputSuggestionsEntity('author');
        authorEntity.setTitle('Имя автора');
        authorEntity.setRequired(true);

        const sortEntity = new InputEntity('sort');
        sortEntity.setTitle('Порядок сортировки');

        const sizesEntity = new InputEntity('sizes');
        sizesEntity.setTitle('Размеры');

        const yearEntity = new InputEntity('year');
        yearEntity.setTitle('Время создания');

        const materialEntity = new InputEntity('material');
        materialEntity.setTitle('Материал, состав');
        materialEntity.setSubTitle('Например, для картин - Масло, Холст');

        const photosEntity = new ImageCollectionEntity('photos');
        photosEntity.setTitle('Фото');
        photosEntity.setRequired(true);

        this.model = {
            name: nameEntity.entity[nameEntity.entityName],
            author: authorEntity.entity[authorEntity.entityName],
            sort: sortEntity.entity[sortEntity.entityName],
            createdAt: '',
            sizes: sizesEntity.entity[sizesEntity.entityName],
            year: yearEntity.entity[yearEntity.entityName],
            material: materialEntity.entity[materialEntity.entityName],
            photos: photosEntity.entity[photosEntity.entityName],
        };
    }
}
