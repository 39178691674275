
import { Vue, Component } from 'vue-property-decorator';
import WorkModule from '@/admin/store/sellers/painters/workEntity';
import Inputmask from 'inputmask';

import InputDefault from '@/components/input/InputMain.vue';
import SelectDefault from '@/components/select/SelectCustom.vue';
import TextareaDefault from '@/components/form/Textarea.vue';
import CheckboxDefault from '@/components/form/CheckboxDefault.vue';
import Loading from '@/components/Loading.vue';
import EditorModal from '@/components/modules/_editorModal.vue';
import BtnDefault from '@/components/BtnDefault.vue';
import ImageCollection from '@/components/imageInput/Collection.vue';
import InputSuggestions from '@/components/input/InputSuggestions.vue';
import { InputSuggestionItem } from '@/lib/formFactory/inputSuggestion.interface';

import BtnSubmit from '@/components/buttons/BtnSubmit/BtnSubmit.vue';
import RemoveModal from '@/components/modules/removeModal.vue';
import { formatNumberSpaced, formatRemoveSpaces } from '@/lib/utils/Utils';
import { numberRegExp } from '@/constants';

@Component({
    components: {
        InputDefault,
        SelectDefault,
        TextareaDefault,
        CheckboxDefault,
        Loading,
        EditorModal,
        BtnDefault,
        ImageCollection,
        InputSuggestions,
        BtnSubmit,
        RemoveModal,
    },
})
export default class PainterWorkCard extends Vue {
    get model() {
        return WorkModule.model;
    }

    get isLoading() {
        return WorkModule.isLoading;
    }

    getFormattedNumberString(value: string | number): string {
        return formatNumberSpaced(value);
    }

    updateName(name: string) {
        WorkModule.updateName(name);
    }

    updateSearchAuthor(search: string) {
        WorkModule.updateSearchAuthor(search);
    }

    updateSelectedAuthor(selected: InputSuggestionItem) {
        WorkModule.updateSuggestionsSelectedByType({ type: 'author', selected });
    }

    handleImage(params: { id: string; file: File }) {
        WorkModule.updatePhotoFileById(params);
    }

    addImages(files: File[]) {
        if (files) {
            WorkModule.addImages(files);
        }
    }

    updateMainPhoto(id: string) {
        WorkModule.updateMainPhoto(id);
    }

    updateImageSort(params: { moveFrom: number; moveTo: number }) {
        WorkModule.updateImageSort(params);
    }

    removeImage(id: string) {
        WorkModule.removeImageById(id);
    }

    updateMaterial(value: string) {
        WorkModule.updateMaterial(value);
    }

    updateYear(value: string) {
        WorkModule.updateYear(value);
    }

    updateSortOrder(value: string) {
        WorkModule.updateSortOrder(formatRemoveSpaces(value));
    }

    updateSizes(value: string) {
        WorkModule.updateSizes(value);
    }

    cancel() {
        if (this.$route.name === 'painter_work_create') {
            this.$router.push({ name: 'painter_works' });

            return;
        }

        this.init();
    }

    send() {
        if (this.$route.name === 'painter_work_create') {
            WorkModule.sendCreate({ name: 'painter_works' });

            return;
        }

        WorkModule.sendEdit();
    }

    redirect(): void {
        this.$router.push({ name: 'painter_works', params: { painterId: this.$route.params.painterId } });
    }

    initMask() {
        const settingsMask = new Inputmask({ regex: numberRegExp, showMaskOnHover: false });
        const inputsNumber = this.$el.getElementsByClassName('j_number_mask_wrapper');

        for (const item of Object.values(inputsNumber)) {
            const input = item.getElementsByTagName('input')[0];
            Inputmask.remove(input);
            settingsMask.mask(input);
        }
    }

    init() {
        if (this.$route.name === 'painter_work_create') {
            WorkModule.SET_IS_LOADING(false);
            WorkModule.initForm(+this.$route.params.painterId);

            return;
        }

        WorkModule.initCardById(this.$route.params.workId);
    }

    mounted() {
        this.init();
        this.initMask();
    }

    beforeDestroy(): void {
        WorkModule.reset();
    }
}
