/*
 *
 * */

import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class ShopsFilter {
    filterHandlers = {
        update: 'PainterWorksFilter/updateFilter',
        reset: 'PainterWorksFilter/resetFilter',
        resetState: 'PainterWorksFilter/resetFilterState',
        removeSelected: 'PainterWorksFilter/removeSelectedFilter',
    };
    filterModel: {};

    constructor() {
        const filter = new FilterFactory();

        filter.createSearchEntity('name');
        filter.setPlaceholder('name', 'По названию');
        filter.setName('name', 'search_name');
        filter.setPreviewName('name', 'Название');
        filter.setIconClassName('name', 'icon-book');
        filter.setClassName('name', 'row-1');
        filter.setAction('name', 'PainterWorks/updateSearch');

        this.filterModel = filter.filterModel;
    }
}
