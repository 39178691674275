export const tabsNav = {
    painter: {
        id: 'painter',
        name: 'Профиль',
        template: 'painter',
        pathName: 'painter',
        icon: 'store',
        iconSize: '16',
    },
    painter_lots: {
        id: 'painter_lots',
        name: 'Лоты',
        template: 'painter_lots',
        pathName: 'painter_lots',
        icon: 'pencil',
        iconSize: '16',
    },
    painter_works: {
        id: 'painter_works',
        name: 'Работы',
        template: 'painter_works',
        pathName: 'painter_works',
        icon: 'pencil',
        iconSize: '16',
    },
    painter_orders: {
        id: 'painter_orders',
        name: 'Заказы',
        template: 'painter_orders',
        pathName: 'painter_orders',
        icon: 'bank',
        iconSize: '16',
    },
    painter_users: {
        id: 'painter_users',
        name: 'Пользователи админпанели',
        template: 'painter_users',
        pathName: 'painter_users',
        icon: 'user',
        iconSize: '16',
    },
    painter_balance: {
        id: 'painter_balance',
        name: 'Баланс',
        template: 'painter_balance',
        pathName: 'painter_balance',
        icon: 'bank',
        iconSize: '16',
    },
};
