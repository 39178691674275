import { Module } from 'vuex-module-decorators';
import store from '@/admin/store';
import FilterModule from '@/admin/store/filter';

@Module({ dynamic: true, store, name: 'PaintersWorksFilter', namespaced: true })
export default class PaintersWorksFilter extends FilterModule {
    constructor(module: PaintersWorksFilter) {
        super(module);
    }
}
