import { Module, getModule, Mutation, Action } from 'vuex-module-decorators';
import store from '@/admin/store';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getLotsList } from '@/admin/api/lots';
import { TableApiInterface, TableInterface } from '@/lib/layouts/page/table.interface';

import FilterModel from './worksFilter';
import FilterEntity from './worksFilterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';

const MODULE_NAME = 'PainterWorks';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PainterWorksModule extends PageBaseModule {
    painterId = 0;
    filter: Filter;

    constructor(module: PainterWorksModule) {
        super(module);

        const page = new PageEntity();
        page.setSortName('painterWorksSort');
        page.setDefaultSortField('name');
        this.pageSettings = page.values;

        const filter = new FilterModel();
        this.filter = getModule(FilterEntity);
        this.filter.setFilterName('painterWorksFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAINTER_ID(id: number) {
        this.painterId = id;
    }

    @Action({ rawError: true })
    async init(id: string) {
        this.SET_PAINTER_ID(+id);
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        const sort = await this.getSortForRequest();
        let filter = `&filters[0][id]=author&filters[0][value]=${this.painterId}`;

        if (!sort && filter.length > 0) {
            filter = `&${filter}`;
        }

        this.SET_IS_LOADING(true);

        const result = await getLotsList(
            this.pageSettings.pageCurrent,
            sort,
            `${filter}${this.filter.filterSettings.filter}`,
            0,
        );
        await this.setList(result);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.prepareTable(table);

        this.SET_TABLE(tableProcessed);
    }

    @Action({ rawError: true })
    prepareTable(table: TableApiInterface) {
        const hiddenColumns = Object.values(table.titles)
            .filter((title) => title.id !== 'name')
            .map((title) => title.id);

        const rows: any = [];

        let index: string;
        let row: any;
        for ([index, row] of Object.entries(table.rows)) {
            const rowProcessed: any = {};
            for (const i of Object.keys(row)) {
                let cell: any = row[i];

                if (i === 'name') {
                    cell = `<span class="cp-table-column__accent">${cell}</span>`;
                }

                rowProcessed[i] = cell;
            }

            rows[index] = rowProcessed;
        }

        return {
            titles: table.titles,
            rows: rows,
            sortFields: table.sortFields,
            pagination: table.pagination ? table.pagination : {},
            totalItems: table.totalItems,
            filters: table.filters,
            hiddenColumns: hiddenColumns,
        } as TableInterface;
    }

    @Action({ rawError: true })
    reset() {
        this.SET_PAINTER_ID(0);
        this.RESET_TABLE();
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }
}

export default getModule(PainterWorksModule);
