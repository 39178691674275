import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import EntityBaseModule from '../../entity';
import store from '@/admin/store';
import EntityModel from './workEntityModel';

import ResponseHandlerModule from '@/admin/store/modules/responseHandler';
import RedirectHandlerModule from '@/admin/store/modules/redirectHandler';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { ImageInput } from '@/lib/formFactory/imageInput.interface';
import { createLotCard, editLotCard, getLotCard } from '@/admin/api/lots';
import ImageInputEntity from '@/lib/formFactory/imageInputEntity';
import { formatCreatedAt, generateSort, getApiErrorMessage, imageFileToBase64, showError } from '@/lib/utils/Utils';
import { getPainterCard, getPainterList } from '@/admin/api/painter';
import { processListForSelect } from '@/lib/utils/form';

const MODULE_NAME = 'PainterWorkEntityModule';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PainterWorkEntityModule extends EntityBaseModule {
    cardId: number | null = null;
    model: any;
    selectDefault = { id: 0, value: '' };
    sellerType = '';
    modalParams: any;
    titleReturnData = {
        edit: {
            title: '',
        },
        create: {
            title: 'Добавление работы',
        },
    };
    requestSort = 'sort[0][id]=name&sort[0][value]=ASC';

    constructor(module: PainterWorkEntityModule) {
        super(module);

        const entityModel = new EntityModel();
        this.model = entityModel.model;
    }

    @Mutation
    SET_CARD_ID(id: number | null) {
        this.cardId = id;
    }

    @Mutation
    SET_NAME(name: string) {
        this.model.name.value = name;
    }

    @Mutation
    SET_IS_RIGHT_TO_FOLLOW(bool: boolean) {
        this.model.isRightToFollow.list[0].checked = bool;
    }

    @Mutation
    SET_SIZES(value: string) {
        this.model.sizes.value = value;
    }

    @Mutation
    SET_LOT_NUMBER(number: string) {
        this.model.lotNumber.value = number;
    }

    @Mutation
    SET_YEAR(year: string) {
        this.model.year.value = year;
    }

    @Mutation
    SET_MATERIAL(material: string) {
        this.model.material.value = material;
    }

    @Mutation
    SET_PHOTOS(list: ImageInput[]) {
        this.model.photos.list = list;
    }

    @Action({ rawError: true })
    async initForm(authorId: number) {
        if (Number.isNaN(authorId)) {
            return;
        }

        const painter = await getPainterCard(authorId);
        this.updateSuggestionsSelectedByType({
            type: 'author',
            selected: painter ? { id: painter.id, value: painter.name } : this.selectDefault,
        });
    }

    @Action({ rawError: true })
    async initCardById(lotId: string) {
        this.SET_IS_LOADING(true);

        const result = await getLotCard(lotId);
        await this.setInfo(result);

        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setInfo(data: any) {
        if (!data) {
            return;
        }

        this.SET_CARD_ID(data.id);
        this.SET_NAME(data.name);
        this.updateSuggestionsSelectedByType({
            type: 'author',
            selected: data.author ? { id: data.author.id, value: data.author.name } : this.selectDefault,
        });
        this.SET_SORT_ORDER(data.sort ? data.sort : '');
        this.SET_CREATED_AT(data.createdAt ? formatCreatedAt(data.createdAt) : '-');

        this.SET_SIZES(data.sizes);

        this.SET_YEAR(data.year ? data.year : '');
        this.SET_MATERIAL(data.material ? data.material : '');

        if (data.photos && data.photos.length > 0) {
            const result = data.photos.map((item: string, index: number) => {
                const key = `photo_${index}`;
                const photo = new ImageInputEntity(key);
                photo.setValue(item);

                return photo.entity[key];
            });

            this.SET_PHOTOS(result);
        }
    }

    @Action({ rawError: true })
    updateName(name: string) {
        this.context.commit('RESET_ERROR_BY_FIELD', 'name');
        this.context.commit('SET_NAME', name);
    }

    @Action({ rawError: true })
    async searchAuthor(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ value: search, type: 'sellerPainter' });

        const table = await getPainterList(1, this.requestSort, `&filters[0][id]=name&filters[0][value]=${search}`, 50);
        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({
            list: await processListForSelect(table.rows),
            type: 'author',
        });
    }

    @Action({ rawError: true })
    updateSortOrder(value: string) {
        this.context.commit('SET_SORT_ORDER', value);
    }

    @Action({ rawError: true })
    updateSizes(value: string) {
        this.context.commit('SET_SIZES', value);
    }

    @Action({ rawError: true })
    updateMaterial(value: string) {
        this.context.commit('SET_MATERIAL', value);
    }

    @Action({ rawError: true })
    updateYear(value: string) {
        this.context.commit('SET_YEAR', value);
    }

    @Action({ rawError: true })
    updateAuthor(item: SelectItem) {
        this.context.commit('SET_AUTHOR', item);
    }

    @Action({ rawError: true })
    async updateSearchAuthor(search: string) {
        this.SET_SUGGESTIONS_VALUE_BY_TYPE({ type: 'author', value: search });
        const table = await getPainterList(
            1,
            await generateSort({ id: 'name', value: 'ASC' }),
            search ? `filters[0][id]=name&filters[0][value]=${search}` : '',
            50,
        );

        this.SET_SUGGESTIONS_HINTS_LIST_BY_TYPE({ type: 'author', list: await processListForSelect(table.rows) });
    }

    @Action({ rawError: true })
    async updatePhotoFileById(params: { id: string; file: File }) {
        const result: ImageInput[] = [];

        for (const item of this.model.photos.list) {
            if (item.key === params.id) {
                item.file = params.file;
                const preview = typeof params.file === 'string' ? params.file : await imageFileToBase64(params.file);
                item.preview = typeof preview === 'string' ? preview : '';
            }

            result.push(item);
        }

        this.context.commit('SET_PHOTOS', result);
    }

    @Action({ rawError: true })
    async addImages(files: File[]) {
        this.RESET_ERROR_BY_FIELD('photos');

        const filesSorted = Array.from(files).sort((a, b) => (a.name < b.name ? -1 : 1));
        const newImages: File[] = [];

        for (const index in filesSorted) {
            const file = files[index];
            const preview = await imageFileToBase64(file);
            const imageEntity = new ImageInputEntity(`photo_${this.model.photos.list.length + index}`);
            const image = imageEntity.entity[imageEntity.entityName];

            image.file = file;
            image.preview = typeof preview === 'string' ? preview : '';

            newImages.push(image);
        }

        this.context.commit('SET_PHOTOS', [...this.model.photos.list, ...newImages]);
    }

    @Action({ rawError: true })
    updateMainPhoto(id: string) {
        let photos = [...this.model.photos.list];
        const mainPhotoIndex = Number(id.split('_')[1]);
        const mainPhoto = photos.splice(mainPhotoIndex, 1)[0];
        photos = [mainPhoto, ...photos];

        photos.forEach((photo, index) => {
            photo.key = `photo_${index}`;
        });

        this.SET_PHOTOS(photos);
    }

    @Action({ rawError: true })
    updateImageSort(params: { moveFrom: number; moveTo: number }) {
        const moveFrom = params.moveFrom;
        const moveTo = params.moveTo;

        const imagesSorted = this.model.photos.list.map((image: ImageInput, index: number) => {
            if (index === moveFrom) {
                return { ...image, key: `photo_${moveTo}` };
            }

            if (moveFrom > moveTo && index >= moveTo && index < moveFrom) {
                return { ...image, key: `photo_${index + 1}` };
            }

            if (moveFrom < moveTo && index > moveFrom && index <= moveTo) {
                return { ...image, key: `photo_${index - 1}` };
            }

            return { ...image };
        });

        imagesSorted.sort((a: ImageInput, b: ImageInput) => {
            return Number(a.key.split('_')[1]) < Number(b.key.split('_')[1]) ? -1 : 1;
        });

        this.SET_PHOTOS(imagesSorted);
    }

    @Action({ rawError: true })
    removeImageById(id: string) {
        const photos = this.model.photos.list.filter((item: ImageInput) => item.key !== id);
        this.context.commit('SET_PHOTOS', photos);
    }

    @Action({ rawError: true })
    async validate() {
        let errorCounter = 0;

        this.setValidateState({ index: 'name', field: this.model.name });
        this.setValidateSelect({ index: 'author', field: this.model.author });
        this.setValidateImageCollection({ index: 'photos', field: this.model.photos });

        const requiredFieldsNames: string[] = Object.entries(this.model)
            .filter((entry: any) => entry[1] != null && entry[1].required === true)
            .map((entry: any) => entry[0]);

        errorCounter += requiredFieldsNames.filter((field) => this.model[field].error.class === 'cp-error').length;

        return errorCounter > 0 ? true : false;
    }

    @Action({ rawError: true })
    prepareRequestData() {
        let data: any = {
            name: this.model.name.value,
            author: this.model.author.current.id,
            isAuthorPainter: '1',
        };

        if (this.model.sort.value) {
            data['sort'] = +this.model.sort.value;
        }

        if (this.model.sizes.value) {
            data['sizes'] = this.model.sizes.value;
        }

        if (this.model.material.value) {
            data['material'] = this.model.material.value;
        }

        if (this.model.year.value) {
            data['year'] = this.model.year.value;
        }

        if (this.model.photos.list.length > 0) {
            const preparedPhotos = this.model.photos.list.reduce(
                (result: { [key: string]: any }, item: ImageInput, index: number) => {
                    if (!item.file && !item.value) return result;

                    if (item.file) {
                        result[`photos[${index}]`] = item.file;
                    }
                    if (item.value) {
                        result[`photos[${index}]`] = item.value;
                    }

                    return result;
                },
                {},
            );

            data = { ...data, ...preparedPhotos };
        }

        return data;
    }

    @Action({ rawError: true })
    async sendEdit() {
        if (this.cardId === null) {
            return;
        }

        try {
            const data = await this.prepareRequestData();
            const result = await editLotCard(this.cardId, data);

            if (!result.message) {
                this.initCardById(result.id);
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    async sendCreate(redirect: any = null) {
        try {
            const data = await this.prepareRequestData();
            const result: any = await createLotCard(data);

            if (!result.message) {
                ResponseHandlerModule.showNotify({ message: 'Изменения сохранены', type: 'ok' });
                RedirectHandlerModule.changeUrl(redirect);
            } else {
                ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
            }
        } catch (error) {
            showError(getApiErrorMessage(error));
        }
    }

    @Action({ rawError: true })
    reset() {
        this.SET_IS_LOADING(true);
        this.SET_CARD_ID(null);
        this.SET_NAME('');
        this.SET_SORT_ORDER('');
        this.SET_CREATED_AT('');
        this.SET_SIZES('');
        this.SET_MATERIAL('');
        this.SET_YEAR('');
        this.SET_PHOTOS([]);
        this.updateSuggestionsSelectedByType({ type: 'author', selected: this.selectDefault });

        this.resetErrorState();
    }

    @Action({ rawError: true })
    resetErrorState() {
        this.RESET_ERROR_BY_FIELD('name');
        this.RESET_ERROR_BY_FIELD('author');
        this.RESET_ERROR_BY_FIELD('photos');
    }
}
export default getModule(PainterWorkEntityModule);
