import { Module, getModule, Action, Mutation } from 'vuex-module-decorators';
import store from '@/admin/store';
import PageBaseModule from '@/admin/store/page';
import PageEntity from '@/lib/layouts/page/pageEntity';
import { getUsersList } from '@/admin/api/users';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareTable } from '@/lib/Users';
import FilterModel from './usersFilter';
import FilterEntity from './/usersFilterEntity';
import { FilterSearchParamsInterface } from '@/lib/layouts/page/filterParams.interface';
import { Filter } from '@/lib/layouts/page/filter.interface';

const MODULE_NAME = 'PainterUsers';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class PainterUsersModule extends PageBaseModule {
    filter: Filter;
    painterId: number;

    constructor(module: PainterUsersModule) {
        super(module);

        this.painterId = 0;

        const page = new PageEntity();
        page.setSortName('painterUsersSort');
        page.setDefaultSortField('fullName');
        this.pageSettings = page.values;

        const filter = new FilterModel();
        this.filter = getModule(FilterEntity);
        this.filter.setFilterName('painterUsersFilter');
        this.filter.setTemplateClassName('template-sm');
        this.filter.setFilterModel(filter.filterModel);
        this.filter.setFilterHandlers(filter.filterHandlers);
    }

    @Mutation
    SET_PAINTER_ID(id: number) {
        this.painterId = id;
    }

    @Action({ rawError: true })
    async init(painterId: number) {
        this.SET_PAINTER_ID(painterId);
        this.setDefaultSort();

        await this.filter.init();

        this.getList();
    }

    @Action({ rawError: true })
    async getList() {
        this.SET_IS_LOADING(true);
        const sort = await this.getSortForRequest();
        let filter = 'filters[0][id]=sellerType&filters[0][value]=painter';
        filter += `&filters[1][id]=sellerPainter&filters[1][value]=${this.painterId}&`;
        filter += this.filter.filterSettings.filter;
        const result = await getUsersList(this.pageSettings.pageCurrent, sort, filter, 0);

        await this.setList(result);
        this.SET_IS_LOADING(false);
    }

    @Action({ rawError: true })
    async setList(table: TableApiInterface) {
        const tableProcessed = await this.getProcessedTable(table);

        this.context.commit('SET_TABLE', tableProcessed);
    }

    @Action({ rawError: true })
    async getProcessedTable(table: TableApiInterface) {
        return await prepareTable(table);
    }

    @Action({ rawError: true })
    reset() {
        this.context.commit('SET_TABLE', {});
    }

    @Action({ rawError: true })
    async updateSearch(params: FilterSearchParamsInterface) {
        await this.filter.updateSearch(params);
        this.filter.updateFilter();
    }
}

export default getModule(PainterUsersModule);
