import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ImageInputDragMixin extends Vue {
    isOverlayHidden = true;

    onDragEnter(event: DragEvent): void {
        const isDraggingFiles = event.dataTransfer?.types.some((type) => type == 'Files');
        this.isOverlayHidden = isDraggingFiles ? false : true;
    }

    onDragOver(event: DragEvent): void {
        event.preventDefault();
    }

    onDragLeave(event: DragEvent): void {
        if (event && event.pageX == 0 && event.pageY == 0) {
            this.hideOverlay();
        }
    }

    hideOverlay(): void {
        this.isOverlayHidden = true;
    }

    mounted(): void {
        window.addEventListener('dragenter', this.onDragEnter);
        (this.$refs.ImageCollectionOverlay as any)?.$el?.addEventListener('dragover', this.onDragOver);
        (this.$refs.ImageCollectionOverlay as any)?.$el?.addEventListener('dragleave', this.onDragLeave);
    }

    beforeDestroy(): void {
        window.removeEventListener('dragenter', this.onDragEnter);
    }
}
